.article {
    position: relative !important;

}

.article img {
    height: 65vh;
    object-fit: cover;
    width: 100%;
}

.absolute {
    position: absolute !important;
    bottom: 50px;
    left: 50px;
    color: white !important;
}