h1 {
  font-size: 45px !important;
  font-weight: bold;
  margin: 0px !important;
  padding: 0px !important;
}

p {
  font-size: 17px !important;
}

.shr {
  width: 50%;
  height: 3px !important;
  background-color: black;
  margin-bottom: 20px;
  margin-top: 10px;

}

.ahr {
  width: 50%;
  height: 3px !important;
  background-color: black;
  margin-bottom: 20px;
  margin-top: 10px;

}

.hero-section {
  width: 100%;
  min-height: 100vh;
  height: auto !important;
  background-image: url("./Assets/images/Background.png");
  background-position: left;
  background-size: cover;

}

.hero-section-heading {
  font-size: 50px !important;
}

.hero-section-heading {
  margin: 10px 0px !important;
}

.hero-section-heading span {

  padding: 5px 10px !important;
  background-color: #16b5ff !important;
  border-radius: 15px !important;
  color: white !important;

}

/* article */
.Article_article__vqqCU img {
  height: auto;
  min-height: 100vh !important;
}

/* Navbar */
.navbar ul li {
  margin: 0px !important;
  list-style: none !important;
  margin: 0px 15px !important;
}

.navbar ul li a {
  color: black !important;
  text-decoration: none !important;
  cursor: pointer;
  font-weight: bold !important;
  font-size: 17px !important;
}

.navbar ul li a:last-child::after {
  display: none !important;
}

.navbar ul li a::after {
  content: "";
  width: 100% !important;
  background-color: black !important;
  height: 2px !important;
  display: block !important;
  transition: 0.4s ease-in-out;
}

.navbar ul li a:hover::after {
  width: 100% !important;
}

.navbar ul li button {
  border: none !important;
  padding: 5px 20px !important;
}


/* what-we-provide */
.what-we-provide .relative {
  position: relative;
}

.what-we-provide .absolute {
  position: absolute;
  bottom: 32px !important;
  z-index: -1;
}

/* services */
.servies .img-1 {
  position: absolute !important;
  max-width: 350px !important;
  z-index: -1 !important;
  bottom: 20px !important;
}

.servies .img-2 {
  position: absolute !important;
  max-width: 350px !important;
  top: 66px !important;
  left: 96px !important;
}

.servies .img-5 {
  position: absolute !important;
  max-width: 350px !important;
  z-index: -1 !important;
  bottom: 20px !important;
}

.servies .img-6 {
  top: 149px;
  position: absolute !important;
  max-width: 350px !important;
  z-index: -1 !important;
  left: 112px;
}

.servies .img-3 {

  position: absolute !important;
  max-width: 350px !important;
  z-index: -1 !important;

}

.servies .img-4 {
  position: absolute !important;
  max-width: 350px !important;
  z-index: -1 !important;
  top: 136px !important;
  left: 111px !important;

}

/* register-pharmacies */
.gutter {
  --bs-gutter-y: 0 !important;
  --bs-gutter-x: 0 !important;

  margin-left: 0px !important;

}

.register-pharmacies .gutter img {
  width: 100% !important;
}
.slick-slider button {
  display: none !important;
}


/* new-technologies */
.new-technologies {
  width: 100% !important;
  height: auto !important;
  position: relative;
  overflow: hidden;

}

.new-technologies video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  z-index: -1;
}

.new-technologies .border {
  width: 70px;
  height: 70px;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-technologies .border i {
  font-size: 37px;
}

/* rating */

.rating h2,
.rating p {
  color: white;
  font-weight: bold;
}

.rating p {
  margin: 0px !important;
  margin-top: 10px !important;
}

.feel-the-best {
  background-image: url('./Assets/images/dna-closely.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
}

.footer {
  border-radius: 8px;
}

.address-box i {
  font-size: 40px;
  margin-right: 15px !important;
}

.logo-box img {
  max-width: 150px !important;
  border-radius: 10px;
}

.links a {
  color: white;
  font-weight: bold;
  text-decoration: none;
}

.links li {
  margin: 15px 0px;
  list-style: none;

}

.news-letter input {
  border-radius: 6px;
  border: none;
  outline: none;
  padding: 10px !important;
}

.news-letter a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

/* social .links */
.social-links .circle {
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: black !important;
  border-radius: 50% !important;
  cursor: pointer !important;
  margin: 20px !important;
}

.social-links i {
  color: white !important;
}

.social-links a {
  text-decoration: none !important;
}

/* ----BG------- */
.bg {
  width: 100%;
  height: 90vh;
  background-image: url('./Assets/images/bg.png');
}


/* carosul */
.image-slider {
  background-image: url('./Assets/images/ezgif-4-7f7a92cd6d.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  margin: 30px 0px;
}

.image-slider img {
  border-radius: 50%;
}

/* blog  */

.Article_article__vqqCU img {
  height: 100vh !important;
}

/* priscription-picture */
.priscription-picture {
  box-shadow: 0px 0px 7px rgb(68, 68, 68);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
}

.priscription-picture img {
  height: 100px;
  width: 100px;

}

.priscription-picture h2 {
  font-size: 30px;
}

/* services hero section */
.services-hero-section {
  background-image: url('./Assets/images/Background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  height: auto;
  width: 100%;
}




.slider {
  background-color: #16b5ff !important;
  padding: 20px !important;
  border-radius: 15px;
  display: flex !important;
  align-items: center !important;
  justify-content: end !important;
  flex-direction: column !important;
  text-align: center !important;
  color: white;
  font-weight: bold;
  width: 95% !important;
  margin: 0px auto;
  position: relative !important;
  position: relative;
  height: auto !important;

}



.slider .img-box {
  position: absolute !important;
  bottom: 262px !important;
}

.slider i {
  font-size: 80px !important;
  color: black;
  margin-left: 20px !important;


}

.slick-list {

  overflow-y: initial !important;
  overflow-x: clip !important;
}

.slider p {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}


@media screen and (max-width:991px) {
  .hero-section .navbar img {
    width: 200px !important;
  }


  h1 {
    font-size: 30px !important;
  }

  .servies .img-1,
  .servies .img-2,
  .servies .img-3,
  .servies .img-4,
  .servies .img-5,
  .servies .img-6 {
    max-width: 230px !important;
  }

  .headingTwo {
    font-size: 30px !important;
  }

  button {
    padding: 5px 10px !important;
  }

  .register-pharmacies img {
    width: 90px !important;
  }

  #sm-img {
    width: 60px !important;
  }

  .servies .img-2 {

    left: 85px !important;
  }

  .servies .img-4 {

    top: 109px !important;
    left: 86px !important;
  }

  .hero-section-heading {
    font-size: 30px !important;
  }

  .hero-box {
    padding: 0px !important;
    padding-top: 30px !important;
  }

  .what-we-provide img {
    width: 250px !important;
  }

  .what-we-provide .absolute {
    position: absolute !important;
    bottom: 20px !important;
  }

  .app,
  .play {
    width: 100px !important;
  }

  .hero {
    padding-top: 0px !important;
  }

  .Article_absolute__aeUyh {
    left: 20px !important;
  }

}



@media screen and (max-width:767px) {
  .hero-section .navbar img {
    width: 200px !important;
  }

 
    .slider .img-box {
      position: absolute !important;
      bottom: 294px !important;
    }
  

  .my-col {
    flex-direction: column !important;
    text-align: center;
  }

  .navbar .container-fluid {
    padding: 0px !important;
  }

  .hero-box {
    display: flex;
    align-items: center;
    flex-direction: column;
  }


  h1 {
    font-size: 25px !important;
  }

  .col {
    display: flex;
    flex-direction: column !important;
  }

  .order {
    text-align: center !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .r-p {
    padding: 0px !important;
  }

  .shr {
    width: 30% !important;
  }

  .ahr {
    width: 30% !important;
  }





  .minus {
    order: 2 !important;
  }

  .servies .img-1,
  .servies .img-2,
  .servies .img-3,
  .servies .img-4,
  .servies .img-5,
  .servies .img-6 {
    max-width: 200px !important;
    position: unset !important;
    margin-top: 20px !important;
  }



  .img-setting {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .servies {
    margin-bottom: 50px !important;
  }

  .padding-remove {
    padding: 0px !important;
    justify-content: space-between !important;
  }



  .feel-the-best {
    padding: 30px 20px !important;
    margin-bottom: 40px;
  }

  .address-box div {
    margin: 30px !important;
  }

  .order {
    order: -1 !important;
  }

  .hero {
    text-align: center !important;
    align-items: center !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }

  .Article_absolute__aeUyh {
    left: 15px !important;
  }

}

@media screen and (max-width:576px) {
  .blog h2 {
    font-size: 20px !important;
  }

  .news-letter,
  .links {
    text-align: center;
  }

  .social-links {
    text-align: center;
  }

  .padding {
    padding: 30px !important;
  }

  .my-col {
    flex-direction: column !important;
    text-align: center;
  }

  .servies img {
    display: none;
  }

  .headingTwo {
    text-align: center;
  }

  .what-we-provide img {
    width: 200px !important;
  }

  .what-we-provide .absolute {
    position: absolute !important;
    bottom: 19px !important;

  }

  .what-we-provide .absolute h1 {
    font-size: 20px !important;
  }

  .headingTwo {
    font-size: 20px !important;
  }

  .padding-remove {
    flex-direction: column !important;
  }

  .padding-remove div {
    margin: 20px 0px !important;
  }

  .info {
    text-align: center !important;
    align-items: center !important;
  }

  p {
    font-size: 15px !important;
  }

  .navbar-logo {
    width: 150px !important;
  }

  h1 {
    font-size: 20px !important;
  }

  .hero-section-heading {
    font-size: 24px !important;
  }

  .hero {
    text-align: center !important;
    align-items: center !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }

  .remove-padding {
    padding: 0px !important;
  }

  .order {
    order: -1 !important;
  }

  .servies div {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

  .last-change {
    padding: 60px !important;
  }

  .start {
    justify-content: unset !important;

  }

  /* .slider i {
    font-size: 40px !important;

    left: 30px;

  } */

  .hide-img {
    display: none !important;
  }

  .width {
    width: 80%;
  }

  .servies {
    margin-bottom: 0px !important;
  }

  .navbar-toggler {
    margin-right: 20px !important;
  }
}

@media screen and (max-width:505px) {
  .slider .img-box{
    bottom: 277px !important;
  }
  .slider .img-box img{
    width: 130px !important;
  }
  .slider i{
    font-size: 40px !important;
  }
}
@media screen and (max-width:450px) {
  .slider .img-box{
    bottom: 311px !important;
  }
  .slider .img-box img{
    width: 130px !important;
  }
  .slider i{
    font-size: 40px !important;
  }
}
@media screen and (max-width:423px) {
  .slider .img-box{
    bottom: 355px !important;
  }
  .slider .img-box img{
    width: 110px !important;
  }
  .slider i{
    font-size: 40px !important;
  }
}
@media screen and (max-width:374px) {
  .slider .img-box{
    bottom: 394px !important;
  }
  .slider .img-box img{
    width: 110px !important;
  }
  .slider i{
    font-size: 40px !important;
  }
}
@media screen and (max-width:340px) {
  .slider .img-box{
    bottom: 440px !important;
  }
  .slider .img-box img{
    width: 100px !important;
  }
  .slider i{
    font-size: 40px !important;
  }
}

@media screen and (max-width:312px) {
  .slider .img-box{
    bottom: 466px !important;
  }
  .slider .img-box img{
    width: 100px !important;
  }
  .slider i{
    font-size: 40px !important;
  }
}
@media screen and (max-width:1025px) {
  .hero-section-heading {
    font-size: 36px !important;
  }

}
body{
  overflow-x: hidden !important;
}