.heroSection {
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-image: url("../../../Assets/images/Background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.ul {
    list-style: none;
    padding: 0px !important;
    margin-top: 15px !important;
}

.ul li {
    font-size: 17px !important;
    margin: 15px 0px !important;
}

.ul li i {
    color: #00ADE5 !important;
    font-size: 13px;
    margin-right: 10px !important;
}
.images{
    margin: 0px !important;
}